.meya-chat-box {
    position: relative;
    height: calc(100% - 0px) !important;
    width: 100%;
    overflow: hidden;
    padding-bottom: 120px;
}

.meya-chat-box-message-header {
    height: 40px;
}

.chat-roomname {
    font-weight: 600;
    color: #566573;
}

.meya-chat-box .meya-chat-box-message-wrapper {
    flex-grow: 1;
    position: relative;
    width: 100%;
    height: calc(100% - 25px) !important;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, .07);
    background-color: #fff;
}

.meya-chat-box-status {
    display: flex;
    justify-content: start;
}

.meya-chat-box .meya-chat-box-tool-wrapper {
    display: flex;
    position: absolute;
    bottom: 75px;
    height: 70px !important;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .07);
    padding: 5px;
    background-color: #fff;
}


.meya-chat-box .meya-chat-box-tool-wrapper .writer-message {
    height: 50px;
    border: none;
    padding: 2px;
    max-height: calc(100% - 10px) !important;
    min-width: calc(100% - 50px) !important;
    resize: none;
}

.meya-chat-box .meya-chat-box-tool-wrapper .writer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 30px !important;
    height: 30px !important;
    background-color: #34A6F7;
    border-radius: 2px;
    cursor: pointer;
    margin-left: 5px;
}

.meya-chat-box .meya-chat-box-tool-wrapper .writer-btn i {
    color: #fff;
    font-size: 14px;
}


.chat {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.chat-message {
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-chat {
    background-color: #FFF;
    height: 30px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, .07);
}

.chat .header-chat .icon {
    margin-left: 30px;
    color: #515151;
    font-size: 14pt;
}

.chat .header-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 13pt;
    color: #515151;
}

.chat .header-chat .right {
    position: absolute;
    right: 40px;
}

.chat .message {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-left: 10px;
    margin-right: 10px;
}

.chat .message .photo {
    display: block;
    width: 45px !important;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80);");
}

.chat .text {
    margin: 0 0px;
    background-color: #f6f6f6;
    padding: 8px;
    border-radius: 5px;
}

.time {
    font-size: 10px;
    color: #aeb6bf;
    margin-bottom: 5px;
    margin-left: 10px;
}

.response-time {
    float: right;
    margin-right: 10px !important;
    margin-left: auto;
    font-size: 10px;
    color: #aeb6bf;
    margin-bottom: 5px;
}

.response {
    float: right;
    margin-right: 0px !important;
    margin-left: auto;
    /* flexbox alignment rule */
}

.response .text {
    color: #fff;
    background-color: #3498db !important;
}

.footer-chat *::-webkit-input-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
}

.footer-chat input *:-moz-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
}

.footer-chat input *::-moz-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
    margin-left: 5px;
}

.footer-chat input *:-ms-input-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
}

.online {
    position: relative;
    top: 30px;
    left: 35px;
    width: 13px;
    height: 13px;
    background-color: #8BC34A;
    border-radius: 13px;
    border: 3px solid #FAFAFA;
}

.chat-load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.load-more-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 20px;
    cursor: pointer;
    font-family: 'Kanit';
}

.load-more-btn:hover {
    background-color: #f8f9f9;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.chat-room-list {
    max-height: 70vh;

}

.chat-room-list .list-group {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 10px;
}

.room-shop-header {
    font-weight: 600;
    color: lightslategrey;
}

.cursor-pointer {
    cursor: pointer;
}

.room-list span {
    display: flex;
    float: right;
    background-color: darkorange;
    height: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: auto;
    font-weight: 500;
    color: #000;
    border-radius: 3px;
}

.meya-chat-box-tool-wrapper textarea {
    border: none !important;
    outline: none !important;
}