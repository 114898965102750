.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#modal-file {
  max-height: 98vh;
}

.file-box {
  position: relative;
  display: flex;
  overflow: auto;
  height: auto;
  padding: 2px;
  min-height: 70vh;
  max-height: 70vh;
  border: 1px solid #ccc;
}

.file-wrapper {
  width: 100%;
  height: auto;
}

.file-wrapper img {
  width: 100%;
  height: auto;
}


.list-group-item.sub-active {
  color: #4d627b !important;
  font-weight: 700;
  border-radius: 0.1rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: #cacfd2;
}

a.sub-active i {
  color: #4d627b !important;
  font-weight: 600;
}

.list-group.admin-console {
  padding-left: 0px !important;
  padding-right: 3px !important;
}

.list-group .list-group-item {
  margin-bottom: 2px;
}

table tr.admintable-header th {
  font-family: 'Poppins';
  font-weight: 600;
}

i.icon-disable {
  color: red !important
}

.border-right {
  border-right: 1px solid lightgray;
}

.border-left {
  border-left: 1px solid lightgray;
}

.font-bold {
  font-weight: 600;
}

.form-group {
  position: relative;
}

.form-control.filtered {
  border: 1px solid red;
}

.form-group .form-control-append {
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  width: 25px;
  height: 35px;
}

.form-control-append i {
  align-items: center;
  vertical-align: middle;
  font-size: 18px;
  margin: auto;
}

.form-control-append:hover {
  cursor: pointer;
}

@-webkit-keyframes tag-warning {
  from {
      background-color: #F39C12;
  }

  to {
      background-color: grey;
  }
}

@-moz-keyframes tag-warning {
  from {
      background-color: #F39C12;
  }

  to {
      background-color: grey;
  }
}

@-o-keyframes tag-warning {
  from {
      background-color: #F39C12;
  }

  to {
      background-color: grey;
  }
}

@keyframes tag-warning {
  0% {
      background-color: yellow;
      color: black;
  }

  50% {
      background-color: grey;
      color: black;
  }

  100% {
      background-color: #F39C12;
      color: black;
  }
}

.tag-warning {
  -webkit-animation: tag-warning 1s steps(1, end) infinite;
  /* Safari 4+ */
  -moz-animation: tag-warning 1s steps(1, end) infinite;
  /* Fx 5+ */
  -o-animation: tag-warning 1s steps(1, end) infinite;
  /* Opera 12+ */
  animation: tag-warning 1s steps(1, end) infinite;
  /* IE 10+ */
}