.card-login {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    min-width: 360px;
    font-family: 'Poppins' !important;
}

.card-login .login-header {
    font-size: 1.3em;
    color: #4d627b !important;
}

.card-login .login-subheader {
    font-size: 1.1em;
    color: slategrey;
}

.card-login .logo {
    width: 70px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}