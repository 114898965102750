.header__content {
    border-bottom: solid 1px #D6DBDF;
}

.app-box {
    margin-left: 15px;
    padding: 0x !important;
    vertical-align: middle;
}

.header-appname {
    font-weight: 600;
    font-size: 0.8rem;
    margin-bottom: 0;
}

.header-appname-sub {
    font-weight: 400;
    color: black;
    margin-top: 0;
    margin-bottom: 0;
}

.header__content-end img.img-flags {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border: 1px solid dimgray;
}

.header__content-end .usersbox {
    font-family: 'Poppins' !important;
    font-weight: 600;
}

.header__content-end .userslogin {
    font-family: 'Poppins' !important;
    font-weight: 600;
    padding: 5px;
    background-color: limegreen;
    color: #fff;
    border-radius: 5px;
    min-width: 50px;
    text-align: center;
}

.btn-outline-secondary-pepsi {
    color: #1f344b;
    border-color: #1f344b;
    font-weight: 600;
}

.btn-outline-secondary-pepsi:hover {
    color: #1f344b;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

ul li .dropdown-item.pepsi-item:hover {
    cursor: pointer;
}

.dropdown-menu-end {
    border-radius: 2px;
}

i.username {
    color: #03a9f4 !important;
}

.mn--min .mininav-content {
    position: fixed;
    inset: 0px auto auto 0px 0px;
    margin: 0px;
    border-left: 5px solid transparent;
}

.nav-label-header-pop {
    display: none;
}

.mn--min:not(.mn--show) .nav-label-header-pop {
    display: flex;
    padding-bottom: 3px;
    border-bottom: 1px solid #808b96;
}

a.msg-unread {
    font-family: 'Poppins' !important;
    font-weight: 600;
    background-color: darkorange;
    color: #fff;
    border-radius: 5px;
    cursor: pointer !important;
    width: 100%;
    height: 100%;
    padding: 5px;
    text-decoration: none;
}