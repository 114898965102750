.card-mycart {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .21rem;
}

.card-mycart>hr {
    margin-right: 0;
    margin-left: 0
}

.card-mycart>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card-mycart>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(.4375rem - 1px);
    border-top-right-radius: calc(.4375rem - 1px)
}

.card-mycart>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(.4375rem - 1px);
    border-bottom-left-radius: calc(.4375rem - 1px)
}

.card-mycart>.card-mycart-header+.list-group,
.card-mycart>.list-group+.card-mycart-footer {
    border-top: 0
}

.card-mycart-header {
    padding: 0px;
}

.card-mycart-title-shopname,
.card-mycart-header .card-mycart-title {
    margin-bottom: .1rem;
    font-size: 16px !important;
    font-weight: 200;
    color: #606060;
    padding: 0;
}

.card-mycart-header .card-mycart-subtitle {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
}

.card-mycart-body {
    flex: 1 1 auto;
    padding: 0.6rem 1.0rem;
    border-top: 1 solid #ccc;
}

.card-mycart-text:last-child {
    margin-bottom: 0
}

.card-mycart-link:hover {
    text-decoration: none
}

.card-mycart-link+.card-mycart-link {
    margin-left: 1.25rem
}

.card-mycart-header {
    padding: .9375rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, .07)
}

.card-mycart-header:first-child {
    border-radius: calc(.4375rem - 1px) calc(.4375rem - 1px) 0 0
}

.card-mycart-footer {
    padding: .9375rem 1.25rem;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, .07)
}

.card-mycart-footer:last-child {
    border-radius: 0 0 calc(.4375rem - 1px) calc(.4375rem - 1px)
}

.card-mycart-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.9375rem;
    margin-left: -.625rem;
    border-bottom: 0
}

.card-mycart-header-pills {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-mycart-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(.4375rem - 1px)
}

.card-mycart-img,
.card-mycart-img-bottom,
.card-mycart-img-top {
    width: 100%
}

.card-mycart-img,
.card-mycart-img-top {
    border-top-left-radius: calc(.4375rem - 1px);
    border-top-right-radius: calc(.4375rem - 1px)
}

.card-mycart-img,
.card-mycart-img-bottom {
    border-bottom-right-radius: calc(.4375rem - 1px);
    border-bottom-left-radius: calc(.4375rem - 1px)
}

.card-mycart-group>.card-mycart {
    margin-bottom: .5rem
}

@media (min-width:576px) {
    .card-mycart-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-mycart-group>.card-mycart {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-mycart-group>.card-mycart+.card-mycart {
        margin-left: 0;
        border-left: 0
    }

    .card-mycart-group>.card-mycart:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-mycart-group>.card-mycart:not(:last-child) .card-mycart-header,
    .card-mycart-group>.card-mycart:not(:last-child) .card-mycart-img-top {
        border-top-right-radius: 0
    }

    .card-mycart-group>.card-mycart:not(:last-child) .card-mycart-footer,
    .card-mycart-group>.card-mycart:not(:last-child) .card-mycart-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-mycart-group>.card-mycart:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-mycart-group>.card-mycart:not(:first-child) .card-mycart-header,
    .card-mycart-group>.card-mycart:not(:first-child) .card-mycart-img-top {
        border-top-left-radius: 0
    }

    .card-mycart-group>.card-mycart:not(:first-child) .card-mycart-footer,
    .card-mycart-group>.card-mycart:not(:first-child) .card-mycart-img-bottom {
        border-bottom-left-radius: 0
    }
}

.mycart-box-check {
    width: 30px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer !important;
    z-index: 900;
}

.mycart-box-image {
    width: 80px;
}

.mycart-box-image img {
    width: 80px;
}

.mycart-box-productname {
    min-width: 300px;
    max-width: 300px;
    padding: 10px 5px 0px 10px !important;
    font-weight: 100;
    font-family: 'Kanit';
    font-size: 12px;
}

.mycart-box-productinter {
    min-width: 300px;
    max-width: 300px;
    padding: 0px 10px 10px 10px !important;
    font-weight: 100;
    font-size: 12px;
    color: gray;
}

.mycart-box-pricelist {
    width: auto;
}

.mycart-box-pricelist .mycart-table-pricelist-row {
    width: auto;
    height: 30px !important;
}

.mycart-box-pricelist .product-unit {
    font-size: 12px;
    min-width: 80px;
    max-width: 300px;
    padding: 0px 10px 0px 10px !important;
}

.mycart-box-pricelist .product-price {
    font-size: 12px;
    min-width: 70px;
    max-width: 300px;
    padding: 0px 10px 0px 10px !important;
}

.mycart-box-pricelist .product-stock {
    font-size: 12px;
    min-width: 125px !important;
    max-width: 300px;
    padding: 0px 10px 0px 10px !important;
}

.mycart-box-pricelist .product-quantity {
    min-width: 62px;
    max-width: 62px;
    margin-left: 20px;
    padding: 0px 10px 0px 10px !important;
}

.mycart-box-pricelist .product-subtotal {
    min-width: 80px;
    max-width: 300px;
    padding: 0px 10px 0px 10px !important;
}

.cartremovebutton,
.mycart-table-pricelist-row td .removebutton {
    display: flex;
    justify-content: center;
    padding: 0px 10px 0px 10px;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #ecedf2;
    max-width: 160px;
    margin-bottom: 0;
}

.cartremovebutton:hover,
.mycart-table-pricelist-row td .removebutton:hover {
    color: #fff;
    background-color: #cd2f22;
    border-color: #c22d20;
    box-shadow: 0 4px 11px rgba(222, 68, 55, 0.35);
}

.mycart-summary {
    top: 0;
    min-width: 300px;
    border: 1px solid rgba(0, 0, 0, .07);
    height: auto;
    padding: 15px 15px 15px 15px !important;

}

.mycart-summary .location-box {
    border-bottom: 1px solid rgba(0, 0, 0, .07);
    width: 100%;
}

.mycart-summary .summary-box {}

.mycart-summary .table-summary {
    display: table;
    width: 100%;
}

.mycart-summary .table-summary-row {
    display: table-row;
}

.mycart-summary .table-summary-cell {
    display: table-cell;
}

.table-summary-cell.aligh-right {
    text-align: right;
}

.repayment-box .payment-method-box,
.mycart-summary .payment-method-box {
    position: relative;
    display: block;
    padding: 5px 10px 0px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
}

.repayment-box .payment-method-box h5,
.mycart-summary .payment-method-box h5 {
    font-size: 14px;
    margin-bottom: 0px;
}

.repayment-box .payment-method-box h6,
.mycart-summary .payment-method-box h6 {
    font-size: 12px;
    margin-bottom: 0px;
}

.repayment-box .payment-method-box p,
.mycart-summary .payment-method-box p {
    font-size: 10px;
    margin-bottom: 5px;
}

.repayment-box .payment-method-box span,
.mycart-summary .payment-method-box span {
    color: red;
}

.repayment-box .payment-method-box.active,
.mycart-summary .payment-method-box.active {
    border: 1px solid #34A6F7 !important;
}

.mycart-summary .payment-method-box .form-control-sm.w100p {
    width: 100%;
}

.mycart-summary .payment-method-box .form-control-sm.w80px {
    width: 80px;
}

.mycart-summary .payment-method-box .form-control-sm.w50px {
    width: 50px;
}

.repayment-box .payment-method-box .payment-method-selected,
.mycart-summary .payment-method-box .payment-method-selected {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #34A6F7;
    position: absolute;
    right: 10px;
    top: 10px;
    display: none;
    justify-content: center;
    font-size: 12px;
}

.repayment-box .payment-method-box .payment-method-selected i,
.mycart-summary .payment-method-box .payment-method-selected i {
    align-items: center;
    margin: auto;
    color: #fff;
}

.repayment-box .payment-method-box .payment-method-selected.active,
.mycart-summary .payment-method-box .payment-method-selected.active {
    display: flex;
}

input.disable-spin::-webkit-outer-spin-button,
input.disable-spin::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number].disable-spin {
    -moz-appearance: textfield;
}

.text-card-expireslash {
    font-size: 16px;
    color: #ccc;
    margin-left: 5px;
    margin-right: 5px;
}

.shipping-recievename,
.shipping-telno,
.shipping-address {
    margin-bottom: 5px;
}

.shipping-table {
    display: table;
}

.shipping-row {
    display: table-row;
    height: 30px;
}

.shipping-cell {
    display: table-cell;
}

.btn-editshipping {
    color: #34A6F7;
    cursor: pointer;
}

.btn-editshipping i {
    font-size: 12px;
}

.shipping-editbox .form-control-sm {
    min-width: 350px;
}

.shipping-editbox .form-control-sm.address {
    min-width: 350px;
}

.shipping-editbox {
    min-width: 400px;
    max-width: 400px;
}

.shipping-editbox .editname {
    min-width: 140px !important;
}

.cart-noitem {
    width: 300px;
    height: 50px;
    background-color: #f8f9f9;
    color: black;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    padding-left: 12px;
}

.payment-table {
    background-color: transparent !important;
    border: none !important;
}

.payment-table tr,
.payment-table td {
    background-color: transparent !important;
    border: none;
}