.ant-card {
    font-feature-settings: "tnum", "tnum";
    background: #fff;
    border-radius: 2px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    overflow-wrap: break-word;
    height: 100%;

    display: flex;
    flex-flow: row wrap;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.ant-card-bordered {
    border: 1px solid #f0f0f0;
}

.ant-card:hover {
    cursor: pointer;
    transform: scale(1.0, 1.0);
    z-index: 100;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.ant-card .ant-card-info {
    min-height: 36px;
    margin-bottom: 0px;
    padding: 0px;
}

.ant-card-info .ant-card-title {
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 0;
    padding-bottom: 0px;
    font-family: 'Kanit' !important;
}

.ant-card-info .ant-card-text {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: -4px;
}

.ant-card .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
}

.ant-card .shopitem-info {
    margin-top: auto;
}

.truncate-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.truncate-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.box-shadow {
    transition-behavior: normal, normal;
    transition-duration: 0.3s, 0.3s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
    transition-property: box-shadow, border-color;
}

.ant-card .bg-image {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
}

.ant-card .img-fluid {
    /* width: 50%;
    height: auto; */
    width: auto;
    max-height: 100px;
    align-items: center;
}

.ant-card-shopname {
    font-size: 0.8925em;
}

.ant-card-province {
    font-size: 0.82em;
}

.ant-card-firstunit {
    font-size: 0.72em;
}

a.ant-card-moredetail {
    font-size: 0.82em;
    color: #383838;
}

a.ant-card-moredetail:hover {
    font-size: 0.9125em;
    color: #34A6F7;
}

.price-table {
    display: table;
}

.price-row {
    display: table-row;
}

.price-cell {
    display: table-cell;
    width: 30%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.price-table-100 {
    display: table;
    width: 100%;
}

.price-row-100 {
    display: table-row;
    width: 100%;
}

.price-cell-25p {
    display: table-cell;
    width: 25%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.ant-card .ribbon-wrapper-red {
    width: 86px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    right: -3px;
}

.ant-card .ribbon-red {
    font: bold 12px Sans-Serif;
    line-height: 16px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    padding: 2px 0;
    left: -18px;
    top: 18px;
    width: 150px;
    background-color: #BF0A30;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.5px;
}

.ant-card .ribbon-red:before,
.ant-card .ribbon-red:after {
    content: "";
    border-top: 4px solid #4e7c7d;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    bottom: -4px;
}

.ant-card .ribbon-red:before {
    left: 0;
}

.ant-card .ribbon-red:after {
    right: 0;
}

.font-size-20 {
    font-size: 1.25038rem;
}

.font-size-16 {
    font-size: 1.00012rem;
}

.text-gray-6 {
    color: #848484 !important;
}