.modal-payment {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    background-color: #fff;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    z-index: 5001;
    max-height: 90vh !important;
    max-width: 90vw !important;

    box-sizing: border-box;
    margin-top: -1px;
    overflow-y: none;
    -webkit-overflow-scrolling: touch;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.modal-payment-wrapper {
    padding: 5px;
    overflow-y: scroll;
    max-height: 90vh !important;
    -webkit-overflow-scrolling: touch;
    border-radius: 5px;
}

.modal-addedit {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    background-color: #fff;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    z-index: 5001;
    max-height: 90vh !important;
    /* min-width: 500px; */
    max-width: 90vw !important;

    box-sizing: border-box;
    margin-top: -1px;
    overflow-y: none;
    -webkit-overflow-scrolling: touch;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.modal-addedit-wrapper {
    padding: 5px;
    overflow-y: scroll;
    max-height: 90vh !important;
    -webkit-overflow-scrolling: touch;
    border-radius: 5px;
    width: 100%;
}